<template>
  <v-container class="pa-0" fluid>
    <page-title :heading="$t('generic.lang_editDeliveryArea')"
                :subheading="$t('generic.lang_editDeliveryArea')"
                :icon="icon"
                show-previous-button
    ></page-title>
    <div class="app-main__inner">
      <DeliveryAreaEditComponent/>
    </div>
  </v-container>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import DeliveryAreaEditComponent from "@/components/delivery/deliveryarea/DeliveryAreaEditComponent";


export default {
  components: {
    DeliveryAreaEditComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-map-marker icon-gradient bg-tempting-azure',
  })
}
</script>
