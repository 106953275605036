<template>
  <v-card>
    <v-card-title>
      {{$t('generic.lang_editDeliveryArea')}}
    </v-card-title>
    <v-divider class="pa-0 ma-0" />
    <v-container>
      <v-form v-model="valid" ref="form">
        <v-row>

          <v-col cols="12" sm="4">
            <v-text-field outlined @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="zip"
              :rules="[v=>(!disableZipCodeCheck?!!v:true)|| $t('generic.lang_requiredField')]"
              :label="$t('generic.lang_zip')" autocomplete="off" :loading="loading" :disabled="loading"></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" :order="disableZipCodeCheck?'first':''">
            <v-text-field outlined @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="areaName"
              :rules="[v=>(disableZipCodeCheck?!!v:true)|| $t('generic.lang_requiredField')]"
              :label="$t('generic.lang_areaName')" autocomplete="off" :loading="loading"
              :disabled="loading"></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field outlined @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="city"
              :label="$t('settings.lang_city')" autocomplete="off" :loading="loading"
              :disabled="loading"></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field outlined @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="minOrder"
              :rules="[v=>(Number(Number(v))>=0)|| $t('generic.lang_requiredField')]" type="number"
              :label="$t('erp.lang_deliveryMinimumOrder')" autocomplete="off" :loading="loading" :disabled="loading"
              :suffix="$store.getters['settings/currencyData']('symbol')"></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field outlined @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="cost"
              :rules="[v=>(Number(Number(v))>=0)|| $t('generic.lang_requiredField')]" type="number"
              :label="$t('erp.lang_Delivery_DeliverySurcharge')" autocomplete="off" :loading="loading"
              :disabled="loading" :suffix="$store.getters['settings/currencyData']('symbol')"></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field outlined @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL"
              v-model="motorcycleDeliveryTime" :rules="[v=>(Number(Number(v))>=0)|| $t('generic.lang_requiredField')]"
              type="number" :label="$t('delivery.lang_motorcyleDeliveryTime')" autocomplete="off" :loading="loading"
              :disabled="loading" prepend-inner-icon="mdi-moped"></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field outlined @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL"
              v-model="carDeliveryTime" :rules="[v=>(Number(Number(v))>=0)|| $t('generic.lang_requiredField')]"
              type="number" :label="$t('delivery.lang_carDeliverTime')" autocomplete="off" :loading="loading"
              :disabled="loading" prepend-inner-icon="mdi-car-sports"></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <v-row no-gutters>
        <v-col cols="12" align="end">
          <v-btn color="success" :loading="loading" :disabled="!valid" @click="update">
            {{$t('generic.lang_apply')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidth" :options="touchKeyboard.options"
        :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible" :layout="touchKeyboard.layout"
        :cancel="hideTouchKeyboard" :accept="hideTouchKeyboard" :input="touchKeyboard.input" />
    </div>
  </v-card>
</template>

<script>
import mixin from '../../../mixins/KeyboardMixIns'
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "DeliveryAreaEditComponent",
  components:{},
  mixins:[mixin],
  data(){
    return{
      ENDPOINTS,
      valid:false,
      loading:true,
      zip:null,
      areaName:null,
      city:null,
      cost:null,
      minOrder:null,
      motorcycleDeliveryTime:null,
      carDeliveryTime:null,
    }
  },
  computed:{
    disableZipCodeCheck() {
      return Number(this.$store.getters['settings/getSettingValue']('lb_disableZipCodeCheck')) === 1
    }
  },
  methods:{
    update(){
      this.loading=true;
      if(!this.$refs.form.validate()){
        return;
      }

      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.DELIVERYAREA.UPDATE,{
        id:this.id,
        zipCode:parseInt(Number(this.zip)),
        areaName:this.areaName,
        city:this.city,
        minOrder:Number(this.minOrder).toFixed(2),
        deliveryCost:Number(this.cost).toFixed(2),
        motorcycleDeliveryTime:parseInt(Number(this.motorcycleDeliveryTime)),
        carDeliveryTime:parseInt(Number(this.carDeliveryTime)),
      }).then(res=>{
        if(res.data.status==="SUCCESS"){
          this.$refs.form.reset();

          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_savedSuccessfully'),
            color: "success"
          });
          this.$router.go(-1);
        }else{
          Events.$emit("showSnackbar", {
            message: res.data.msg||res.data.status,
            color: "error"
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "erro"
        });
      }).finally(fin=>{
        this.loading=false;
      })
    },
    getData(){
      this.id=parseInt(this.$route.params.id)
      this.loading=true;

      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.DELIVERYAREA.GET,{
        id:parseInt(this.$route.params.id)
      }).then(res=>{
        if(res.status===200){
          this.zip=parseInt(res.data.formfillData.textFields.zipCode)
          this.minOrder=Number(res.data.formfillData.textFields.minOrder).toFixed(2)
          this.cost=Number(res.data.formfillData.textFields.deliveryCost).toFixed(2)
          this.motorcycleDeliveryTime=res.data.formfillData.textFields.motorcycleDeliveryTime;
          this.carDeliveryTime=res.data.formfillData.textFields.carDeliveryTime;
          this.areaName=res.data.formfillData.textFields.areaName;
          this.city=res.data.formfillData.textFields.city;
        }else{
          Events.$emit("showSnackbar", {
            message: res.data.msg||res.data.status,
            color: "error"
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(fin=>{
        this.loading=false;
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped>

</style>
